<template>
  <v-row justify="center">
    <v-overlay
      :z-index="zIndex"
      :value="overlay"
    >
     <div>
       Das Tablet ist aktuell besetzt
     </div>
      <v-btn
        @click="ConfirmCancelation"
        x-large
        block
      >
        OK
      </v-btn>
    </v-overlay>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    overlay: true,
    zIndex: 50
  }),
  methods: {
    ConfirmCancelation () {
      // go to main view
      this.$router.push('/mobile')
    }
  }
}
</script>
